import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/orders";
import Datatable from "../common/datatable";
import axios from "axios";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const Orders = () => {
	const [orders, setOrders] = useState([]);

	useEffect(() => {
	  // Fetch orders when the component mounts
	  axios.get(process.env.REACT_APP_API_URL + 'order/get-all-orders') // Adjust the URL to match your API endpoint
		.then(response => {
		  setOrders(response.data.data.orders);
		  console.log(response.data.data.orders);
		})
		.catch(error => {
		  console.error('Error fetching orders:', error);
		});
	}, []);
	return (
		<Fragment>
			<Breadcrumb title="Orders" parent="Sales" />

			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Manage Order</h5>
							</CardHeader>
							<CardBody className="order-datatable">
								<Datatable
									multiSelectOption={false}
									myData={orders}
									pageSize={10}
									pagination={true}
									class="-striped -highlight"
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Orders;
