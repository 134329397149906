import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
//import data from "../../../assets/data/physical_list";
import { Edit, Trash2 } from "react-feather";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

const Product_list = () => {
	const [allProducts, setAllProducts] = useState([]);
	const [bibleProducts, setBibleProducts] = useState([]);
	const [weddingBibleProducts, setWeddingBibleProducts] = useState([]);

	const [selectedCategory, setSelectedCategory] = useState("All");
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [productCount, setProductCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_API_URL + 'product/productList');
			setAllProducts(response.data.products);
		} catch (error) {
			console.error('Error fetching products:', error);
		}
	};

	const deleteProduct = (productId) => {
		axios.delete(process.env.REACT_APP_API_URL + `product/delete/${productId}`)
			.then((response) => {
				toast.success('Product deleted successfully');
				// Remove the deleted product from the state
				setAllProducts(allProducts.filter((product) => product._id !== productId));
			})
			.catch((error) => {
				console.error('Error deleting product:', error);
			});
	};


	useEffect(() => {
		// Filter products based on the selected category
		const filtered = selectedCategory === "All"
			? allProducts
			: allProducts.filter(product => product.category === selectedCategory);

		setFilteredProducts(filtered);
		setProductCount(filtered.length); // Update the product count
	}, [selectedCategory, allProducts]);


	useEffect(() => {
		// Filter products based on the search term from the original products list
		const filteredBySearch = allProducts.filter(product => {
			const propertyValue = product.name;
			if (propertyValue) {
				return propertyValue.toLowerCase().includes(searchTerm.toLowerCase());
			}

		});

		setProductCount(filteredBySearch.length);
		setFilteredProducts(filteredBySearch);
	}, [searchTerm, allProducts]);

	return (
		<Fragment>
			<ToastContainer />
			<Breadcrumb title="Product List" parent="Physical" />
			<Container fluid={true}>
				{/* <div>
        <label>Filter by Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="All">All Categories</option>
          <option value="HolyBible">Holy Bible</option>
          <option value="WeddingBible">Wedding Bible</option>
		  <option value="Board">Board</option>
        </select>
      </div>
	  <div>
        <label>Search by Product Name:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}


				<div className="tabs">
					<div className="tab-buttons">
						<button
							className={activeTab === 'tab1' ? 'active' : ''}
							onClick={() => handleTabClick('tab1')}
						>
							Language Bibles
						</button>
						<button
							className={activeTab === 'tab2' ? 'active' : ''}
							onClick={() => handleTabClick('tab2')}
						>
							Wedding Bible
						</button>
						<button
							className={activeTab === 'tab3' ? 'active' : ''}
							onClick={() => handleTabClick('tab3')}
						>
							Constructed CPU
						</button>
						<button
							className={activeTab === 'tab4' ? 'active' : ''}
							onClick={() => handleTabClick('tab4')}
						>
							Assembled Computers
						</button>
						<button
							className={activeTab === 'tab5' ? 'active' : ''}
							onClick={() => handleTabClick('tab5')}
						>
							Matchboxes
						</button>
						<button
							className={activeTab === 'tab6' ? 'active' : ''}
							onClick={() => handleTabClick('tab6')}
						>
							Used Laptops
						</button>
						<button
							className={activeTab === 'tab7' ? 'active' : ''}
							onClick={() => handleTabClick('tab7')}
						>
							Dry Coconut
						</button>
						<button
							className={activeTab === 'tab8' ? 'active' : ''}
							onClick={() => handleTabClick('tab8')}
						>
							Herbal Powders
						</button>
					</div>

					<div className="tab-content">
						{activeTab === 'tab1' && (
							<>
							<div>
								<h2>Tamil Bible</h2>
								<Row className="products-admin ratio_asos">
									<p>{allProducts && allProducts
										.filter(product => product.category === 'HolyBible' && product.additionalFields.language === 'Tamil').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'HolyBible'  && product.additionalFields.language === 'Tamil').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>


							<div>
								<h2>English Bible</h2>
								<Row className="products-admin ratio_asos">
									<p>{allProducts && allProducts
										.filter(product => product.category === 'HolyBible' && product.additionalFields.language === 'English').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'HolyBible'  && product.additionalFields.language === 'English').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>

							<div>
								<h2>Hindi Bible</h2>
								<Row className="products-admin ratio_asos">
									<p>{allProducts && allProducts
										.filter(product => product.category === 'HolyBible' && product.additionalFields.language === 'Hindi').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'HolyBible'  && product.additionalFields.language === 'Hindi').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>

							<div>
								<h2>Malayalam Bible</h2>
								<Row className="products-admin ratio_asos">
									<p>{allProducts && allProducts
										.filter(product => product.category === 'HolyBible' && product.additionalFields.language === 'Malayalam').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'HolyBible'  && product.additionalFields.language === 'Malayalam').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>

							<div>
								<h2>Kannada Bible</h2>
								<Row className="products-admin ratio_asos">
									<p>{allProducts && allProducts
										.filter(product => product.category === 'HolyBible' && product.additionalFields.language === 'Kannada').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'HolyBible'  && product.additionalFields.language === 'Kannada').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>

							<div>
								<h2>Telugu Bible</h2>
								<Row className="products-admin ratio_asos">
									<p>{allProducts && allProducts
										.filter(product => product.category === 'HolyBible' && product.additionalFields.language === 'Telugu').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'HolyBible'  && product.additionalFields.language === 'Telugu').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
							</>
						)}
						{activeTab === 'tab2' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'WeddingBible').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'WeddingBible').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
						{activeTab === 'tab3' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'Constructed CPU').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'Constructed CPU').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
						{activeTab === 'tab4' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'Assembled Computers').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'Assembled Computers').reverse().map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
						{activeTab === 'tab5' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'Matchboxes').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'Matchboxes').map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
						{activeTab === 'tab6' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'Used Laptops').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'Used Laptops').map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
						{activeTab === 'tab7' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'Dry Coconut').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'Dry Coconut').map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
						{activeTab === 'tab8' && (
							<div>
								<Row className="products-admin ratio_asos">
								<p>{allProducts && allProducts
										.filter(product => product.category === 'Herbal Powders').length} products</p>
									{allProducts && allProducts
										.filter(product => product.category === 'Herbal Powders').map((myData, i) => {
											return (
												<Col xl="3" sm="6" key={i}>
													<Card>
														<div className="products-admin">

															<CardBody className="product-box">
																<div className="img-wrapper">

																	<div className="lable-block">
																		{myData.tag === "new" ? (
																			<span className="lable3">{myData.tag}</span>
																		) : (
																			""
																		)}
																		{myData.discount === "on sale" ? (
																			<span className="lable4">{myData.offerPercentage}</span>
																		) : (
																			""
																		)}
																	</div>
																	<div className="front">
																		<a href="/#" className="bg-size">
																			<img
																				alt=""
																				className="img-fluid blur-up bg-img lazyloaded"
																				src={myData.images[0]}
																			/>
																		</a>
																		<div className="product-hover">
																			<ul>
																				<li>
																					<Link to={`/products/physical/update-product/${myData._id}`}>
																						<Button color="btn" type="button">
																							<Edit className="editBtn" />
																						</Button>
																					</Link>

																				</li>
																				<li>
																					<Button color="btn" type="button">
																						<Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
																					</Button>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className="product-detail">
																	<a href="/#">
																		{" "}
																		<h6>{myData.name}</h6>
																	</a>
																	<h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4>
																</div>
															</CardBody>
														</div>
													</Card>
												</Col>
											);
										})}
								</Row>
							</div>
						)}
					</div>
				</div>


			</Container>
		</Fragment>
	);
};

export default Product_list;
