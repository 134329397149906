import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
//import data from "../../../assets/data/physical_list";
import { Edit, Trash2 } from "react-feather";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

const Board_list = () => {

    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'board/boardList');
            setProducts(response.data.products);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const deleteProduct = (productId) => {
        axios.delete(process.env.REACT_APP_API_URL + `board/delete/${productId}`)
            .then((response) => {
                toast.success('Product deleted successfully');
                // Remove the deleted product from the state
                setProducts(products.filter((product) => product._id !== productId));
            })
            .catch((error) => {
                console.error('Error deleting product:', error);
            });
    };


    useEffect(() => {
        // Filter products based on the selected category
        const filteredByCategory = selectedCategory === "All"
            ? products
            : products.filter(product => product.category === selectedCategory);

        setFilteredProducts(filteredByCategory);
        setProductCount(filteredByCategory.length); // Update the product count
    }, [selectedCategory, products]);



    useEffect(() => {
        // Filter products based on the search term from the original products list
        const filteredBySearch = products.filter(product => {
            const propertyValue = product.name;
            if (propertyValue) {
                return propertyValue.toLowerCase().includes(searchTerm.toLowerCase());
            }

        });

        setProductCount(filteredBySearch.length);
        setFilteredProducts(filteredBySearch);
    }, [searchTerm, products]);


    return (
        <Fragment>
            <ToastContainer />
            <Breadcrumb title="Product List" parent="Physical" />
            <Container fluid={true}>
                {/* <div>
        <label>Filter by Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="All">All Categories</option>
          <option value="HolyBible">Holy Bible</option>
          <option value="WeddingBible">Wedding Bible</option>
		  <option value="Board">Board</option>
        </select>
      </div>
	  <div>
        <label>Search by Product Name:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
                <Row className="products-admin ratio_asos">
                    <p>{productCount} products</p>
                    {products && products.map((myData, i) => {
                        return (
                            <Col xl="3" sm="6" key={i}>
                                <Card>
                                    <div className="products-admin">

                                        <CardBody className="product-box">
                                            <div className="img-wrapper">

                                                <div className="lable-block">
                                                    {myData.tag === "new" ? (
                                                        <span className="lable3">{myData.tag}</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {myData.discount === "on sale" ? (
                                                        <span className="lable4">{myData.offerPercentage}</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="front">
                                                    <a href="/#" className="bg-size">
                                                        <img
                                                            alt=""
                                                            className="img-fluid blur-up bg-img lazyloaded"
                                                            src={myData.images[0]}
                                                        />
                                                    </a>
                                                    <div className="product-hover">
                                                        <ul>
                                                            <li>
                                                                <Link to={`/products/physical/update-board/${myData._id}`}>
                                                                    <Button color="btn" type="button">
                                                                        <Edit className="editBtn" />
                                                                    </Button>
                                                                </Link>

                                                            </li>
                                                            <li>
                                                                <Button color="btn" type="button">
                                                                    <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                </Button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-detail">
                                                <a href="/#">
                                                    {" "}
                                                    <h6>{myData.name}</h6>
                                                </a>
                                                <h4>
                                                    Rs.{myData.minPrice}-Rs.{myData.maxPrice}
                                                </h4>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Fragment>
    );
};

export default Board_list;
