import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Button,
	FormFeedback
} from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Add_product = () => {

	const [data, setData] = useState({
		name: "",
		slug: "",
		sku: "",
		category: "",
		summary: "",
		regularPrice: "",
		offerPrice: "",
		offerPercentage: "",
		stock: "",
		description: "",
		images: [],
		additionalFields: {
			language: "",
			version: "",
			pattern: "",
			bibleSize: "",
			width: "",
			height: "",
			color: "",

		}
	});
	const [uniqueId, setUniqueId] = useState(Date.now());
	const [file, setFile] = useState([]);

	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors },
		watch,
		setValue,
		reset,
	} = useForm({
		defaultValues: data
	});
	const fileChange = (e) => {
		setFile([...file, ...e.target.files]);
	};

	const clearFileState = () => {
		setFile([]);
	};

	const [customErrors, setCustomErrors] = useState({});

	const handleValidSubmit = async (data) => {
		console.log(data, "data");
		try {
			const formData = new FormData();
			const values = getValues();

			for (var j = 0; j < file.length; j++) {
				formData.append("images", file[j]);
			}

			for (const [key, value] of Object.entries(data)) {
				if (key === "size") {
					if (Array.isArray(value)) {
						// If "size" is an array, store its values individually in FormData
						for (let i = 0; i < value.length; i++) {
							formData.append("size", value[i]);
						}
					} else {
						// If "size" is not an array, store it as a single value in FormData
						formData.append("size", value);
					}
				} else {
					formData.append(key, value);
				}
			}
			const response = await axios.post(
				process.env.REACT_APP_API_URL + "product/create",
				formData,
				{
					headers: {
						"Content-type": "multipart/form-data",
						//Authorization: `${token && token.token}`,
					},
				}
			);

			if (response.data.success) {
				toast.success("Product added successfully");
				reset();
				clearFileState();
				window.location.reload();
			} else {
				toast.error("An error occurred while creating the product");
			}
		} catch (error) {
			if (error.response && error.response.status === 400) {
				const { data } = error.response;
				if (data && data.message) {
					if (Array.isArray(data.message)) {
						toast.error(data.message[0]);
					} else {
						toast.error(data.message);
					}
				} else {
					toast.error("An error occurred while processing the request");
				}
			} else if (error.request) {
				toast.error("No response received from the server");
			} else {
				toast.error("An error occurred while sending the request");
			}
		}
	};

	const CustomMDEditor = ({ name, control }) => {
		return (
			<Controller
				name={name}
				control={control}
				defaultValue={data.description}
				render={({ field }) => (
					<MDEditor value={field.value} onChange={field.onChange} />
				)}
			/>
		);
	};

	function deleteFile(e) {
		const s = file.filter((item, index) => index !== e);
		setFile(s);
		setUniqueId(Date.now());
	}

	const category = watch('category');
	const language = watch('language');
	const processor = watch('processor');

	const offerPrice = watch("offerPrice");
	const offerPercentage = watch("offerPercentage");

	useEffect(() => {
		if (offerPrice && offerPercentage) {
			const calculatedRegularPrice = (offerPrice * 100) / (100 - offerPercentage);
			setValue("regularPrice", Math.round(calculatedRegularPrice));  // Fill regular price with rounded value
		}
	}, [offerPrice, offerPercentage, setValue]);
	


	return (
		<Fragment>
			<Breadcrumb title="Add Product" parent="Physical" />
			<ToastContainer />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Add Product</h5>
							</CardHeader>
							<CardBody>
								<Row className="product-adding">
									<Col xl="5">
										<div className="add-product">
											<Col xl="3">
												<div className="add-product">
													{file && file.length < 6 && (
														<Row>
															<Col xl="12 xl-50" sm="6 col-3">
																<Input
																	key={uniqueId}
																	className="upload"
																	type="file"
																	multiple
																	name="images"
																	accept="image/png, image/gif,image/webp, image/jpeg"
																	onChange={fileChange}
																/>
															</Col>
														</Row>
													)}
													<Row style={{ marginTop: "15px" }}>
														{file &&
															file.length > 0 &&
															file.map((item, index) => {
																return (
																	<Col xl="6" sm="3 col-3">
																		<div key={index}>
																			<img
																				style={{ width: "100%" }}
																				src={URL.createObjectURL(item)}
																				alt=""
																			/>
																			<Button
																				style={{ marginTop: "10px" }}
																				type="button"
																				color="secondary"
																				size="sm"
																				onClick={() => deleteFile(index)}
																			>
																				delete
																			</Button>
																		</div>
																	</Col>
																);
															})}
													</Row>
												</div>
											</Col>
										</div>
									</Col>
									<Col xl="7">
										<Form
											className="needs-validation add-product-form"
											onSubmit={handleSubmit(handleValidSubmit)}
										>
											<FormGroup className="form-group mb-3 row">
												<Label className="col-xl-3 col-sm-4 mb-0">
													Category:
												</Label>
												<div className="col-xl-8 col-sm-7">
													<Controller
														name="category"
														control={control}
														defaultValue={data.category}
														render={({ field }) => (
															<>
																<Input type="select" className="form-control" {...field} invalid={!!errors.category}>
																	<option value="">Select Category</option>
																	<option value="HolyBible">HolyBible</option>
																	<option value="WeddingBible">WeddingBible</option>
																	<option value="Led Star">LED Star</option>
																	<option value="Led Serial Sets">LED Serial Sets</option>
																	<option value="Crib House">Crib House</option>
																	<option value="Crib House Dolls">Crib House Dolls</option>
																	<option value="ChristmasTree">ChristmasTree</option>
																	<option value="Santa Dress">Santa Dress</option>
																	<option value="Constructed CPU">Constructed CPU</option>
																	<option value="Assembled Computers">Assembled Computers</option>
																	<option value="Laptops">Laptops</option>
																	<option value="Used Laptops">Used Laptops</option>
																	<option value="Matchboxes">Matchboxes</option>
																	<option value="Dry Coconut">Dry Coconut</option>
																	<option value="Herbal Powders">Herbal Powders</option>
																	{/* Add more options here */}
																</Input>
																{errors.category && (
																	<FormFeedback>{errors.category.message}</FormFeedback>
																)}
															</>
														)}
														rules={{ required: "Category is required" }}
													/>
												</div>
											</FormGroup>


											<div className="form form-label-center">
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Product Name:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="name"
															control={control}
															defaultValue={data.name}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.name}
																	/>
																	{errors.name && (
																		<FormFeedback>{errors.name.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Product Name is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Product URL:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="slug"
															control={control}
															defaultValue={data.slug}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.slug}
																	/>
																	{errors.slug && (
																		<FormFeedback>{errors.slug.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Product URL is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														SKU:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="sku"
															control={control}
															defaultValue={data.sku}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.sku}
																	/>
																	{errors.sku && (
																		<FormFeedback>{errors.sku.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "SKU is required" }}
														/>
													</div>
												</FormGroup>
											</div>

											{category == 'ChristmasTree' && (<>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Tree Type:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="treeType"
															control={control}
															defaultValue={data.additionalFields.treeType}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.treeType}>
																		<option value="">Select Tree Type</option>
																		<option value="Leaf Tree">Leaf Tree</option>
																		<option value="Pine Tree">Pine Tree</option>
																		<option value="Lighting Tree">Lighting Tree</option>
																		{/* Add more options here */}
																	</Input>
																	{errors.additionalFields?.treeType && (
																		<FormFeedback>{errors.additionalFields.treeType.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Tree Type is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Tree Size:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="treeSize"
															control={control}
															defaultValue={data.additionalFields.treeSize}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.treeSize}>
																		<option value="">Select Tree Size</option>
																		<option value="4 Ft">4 Ft</option>
																		<option value="5 Ft">5 Ft</option>
																		<option value="6 Ft">6 Ft</option>
																		<option value="7 Ft">7 Ft</option>
																		<option value="10 Ft">10 Ft</option>
																		{/* Add more options here */}
																	</Input>
																	{errors.treeSize && (
																		<FormFeedback>{errors.treeSize.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Tree Size is required" }}
														/>
													</div>
												</FormGroup>
											</>)}


											{(category === 'Constructed CPU' || category === 'Assembled Computers') && (<>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Processor:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="processor"
															control={control}
															defaultValue={data.additionalFields.processor}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.processor}>
																		<option value="">Select Processor</option>
																		<option value="i3">i3</option>
																		<option value="i5">i5</option>
																		<option value="i7">i7</option>
																		<option value="i9">i9</option>
																		<option value="AMD">AMD</option>
																	</Input>
																	{errors.processor && (
																		<FormFeedback>{errors.processor.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Processor is required" }}
														/>
													</div>
												</FormGroup>


												{(processor === 'i5' || processor === 'i7' || processor === 'i9') && (<>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															VRAM Size:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="vRamSize"
																control={control}
																defaultValue={data.additionalFields.vRamSize}
																render={({ field }) => (
																	<>
																		<Input type="select" className="form-control" {...field} invalid={!!errors.vRamSize}>
																			<option value="">Select VRAM Size</option>
																			<option value="2 GB">2 GB</option>
																			<option value="4 GB">4 GB</option>
																			<option value="6 GB">6 GB</option>
																			<option value="8 GB">8 GB</option>
																			<option value="12 GB">12 GB</option>
																			<option value="16 GB">16 GB</option>
																			<option value="20 GB">20 GB</option>
																			<option value="24 GB">24 GB</option>
																		</Input>
																		{errors.vRamSize && (
																			<FormFeedback>{errors.vRamSize.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "vRamSize is required" }}
															/>
														</div>
													</FormGroup>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Graphics Card Model:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="graphicsCardModel"
																control={control}
																defaultValue={data.additionalFields.graphicsCardModel}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.graphicsCardModel}
																		/>
																		{errors.graphicsCardModel && (
																			<FormFeedback>{errors.graphicsCardModel.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Graphics Card Model is required" }}
															/>
														</div>
													</FormGroup>
												</>)}

												{(processor === 'AMD') && (<>


													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															AMD Variant:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="amdVariant"
																control={control}
																defaultValue={data.additionalFields.amdVariant}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.amdVariant}
																		/>
																		{errors.amdVariant && (
																			<FormFeedback>{errors.amdVariant.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "AMD Variant is required" }}
															/>
														</div>
													</FormGroup>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Graphics Card Model:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="graphicsCardModel"
																control={control}
																defaultValue={data.additionalFields.graphicsCardModel}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.graphicsCardModel}
																		/>
																		{errors.graphicsCardModel && (
																			<FormFeedback>{errors.graphicsCardModel.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Graphics Card Model is required" }}
															/>
														</div>
													</FormGroup>
												</>)}

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Mother Board:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="motherBoard"
															control={control}
															defaultValue={data.additionalFields.motherBoard}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.motherBoard}
																	/>
																	{errors.motherBoard && (
																		<FormFeedback>{errors.motherBoard.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Mother Board is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Generation:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="generation"
															control={control}
															defaultValue={data.additionalFields.generation}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.generation}>
																		<option value="">Select Generation</option>
																		<option value="3rd Generation">3rd Generation</option>
																		<option value="4th Generation">4th Generation</option>
																		<option value="5th Generation">5th Generation</option>
																		<option value="6th Generation">6th Generation</option>
																		<option value="7th Generation">7th Generation</option>
																		<option value="8th Generation">8th Generation</option>
																		<option value="10th Generation">10th Generation</option>
																		<option value="11th Generation">11th Generation</option>
																		<option value="12th Generation">12th Generation</option>
																		<option value="13th Generation">13th Generation</option>
																		<option value="14th Generation">14th Generation</option>
																	</Input>
																	{errors.generation && (
																		<FormFeedback>{errors.generation.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Generation is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Generation Variant
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="generationVariant"
															control={control}
															defaultValue={data.additionalFields.generationVariant}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.generationVariant}
																	/>
																	{errors.generationVariant && (
																		<FormFeedback>{errors.generationVariant.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Generation Vairant is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														OS:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="os"
															control={control}
															defaultValue={data.additionalFields.os}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.os}>
																		<option value="">Select OS</option>
																		<option value="Windows 10">Windows 10</option>
																		<option value="Windows 11">Windows 11</option>
																	</Input>
																	{errors.os && (
																		<FormFeedback>{errors.os.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "OS is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														RAM:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="ram"
															control={control}
															defaultValue={data.additionalFields.ram}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.ram}>
																		<option value="">Select RAM</option>
																		<option value="DDR3">DDR3</option>
																		<option value="DDR4">DDR4</option>
																		<option value="DDR5">DDR5</option>
																	</Input>
																	{errors.ram && (
																		<FormFeedback>{errors.ram.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "RAM is required" }}
														/>
													</div>
												</FormGroup>



												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														RAM Size
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="ramSize"
															control={control}
															defaultValue={data.additionalFields.ramSize}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.ramSize}
																	/>
																	{errors.ramSize && (
																		<FormFeedback>{errors.ramSize.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "RAM Size is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														SSD:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="ssd"
															control={control}
															defaultValue={data.additionalFields.ssd}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.ssd}>
																		<option value="">Select SSD</option>
																		<option value="128 GB">128 GB</option>
																		<option value="256 GB">256 GB</option>
																		<option value="500 GB">500 GB</option>
																		<option value="512 GB">512 GB</option>
																		<option value="1 TB">1 TB</option>
																		<option value="2 TB">2 TB</option>
																	</Input>
																	{errors.ssd && (
																		<FormFeedback>{errors.ssd.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "SSD is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Additional SSD:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="additionalSsd"
															control={control}
															defaultValue={data.additionalFields.additionalSsd}
															render={({ field }) => (
																<>
																	<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.additionalSsd}
																		/>
																	{errors.additionalSsd && (
																		<FormFeedback>{errors.additionalSsd.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Additional SSD is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Hard Disk (HDD):
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="hdd"
															control={control}
															defaultValue={data.additionalFields.hdd}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.hdd}>
																		<option value="">Select HDD</option>
																		<option value="500 GB">500 GB</option>
																		<option value="1 TB">1 TB</option>
																	</Input>
																	{errors.hdd && (
																		<FormFeedback>{errors.hdd.message}</FormFeedback>
																	)}
																</>
															)}
															// rules={{ required: "Hard Disk is required" }}
														/>
													</div>
												</FormGroup>
											</>)}

											{category === 'Assembled Computers' && (
												<>
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Monitor Size:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="monitorSize"
																control={control}
																defaultValue={data.additionalFields.monitorSize}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.monitorSize}
																		/>

																		{errors.monitorSize && <FormFeedback>{errors.monitorSize.message}</FormFeedback>}
																	</>
																)}
																rules={{ required: "Monitor Size is required" }}
															/>
														</div>
													</FormGroup>
												</>
											)}

											{(category === 'HolyBible' || category === 'WeddingBible') && (
												<>
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Language:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="language"
																control={control}
																defaultValue={data.additionalFields.language}
																render={({ field }) => (
																	<>
																		<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.language}>
																			<option value="">Select Language</option>
																			<option value="Tamil">Tamil</option>
																			<option value="English">English</option>
																			<option value="Malayalam">Malayalam</option>
																			<option value="Hindi">Hindi</option>
																			<option value="Kannada">Kannada</option>
																			<option value="Telugu">Telugu</option>
																			{/* Add more options here */}
																		</Input>
																		{errors.additionalFields?.language && (
																			<FormFeedback>{errors.additionalFields.language.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Language is required" }}
															/>
														</div>
													</FormGroup>

													{language === 'English' && (
														<>
															<FormGroup className="form-group mb-3 row">
																<Label className="col-xl-3 col-sm-6 mb-0">
																	Version:
																</Label>
																<div className="col-xl-6 col-sm-7">
																	<Controller
																		name="version"
																		control={control}
																		defaultValue={data.additionalFields.version}
																		render={({ field }) => (
																			<>
																				<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.version}>
																					<option value="">Select Version</option>
																					<option value="niv">NIV</option>
																					<option value="kjv">KJV</option>
																					<option value="nkjv">NKJV</option>
																					<option value="nlt">NLT</option>
																					<option value="nrsv">NRSV</option>
																					<option value="esv">ESV</option>
																					<option value="nasb">NASB</option>
																					<option value="goodnews">GoodNews</option>
																					<option value="amplified">Amplified</option>
																					{/* Add more options here */}
																				</Input>
																				{errors.additionalFields?.version && (
																					<FormFeedback>{errors.additionalFields.version.message}</FormFeedback>
																				)}
																			</>
																		)}
																		rules={{ required: "Language is required" }}
																	/>
																</div>
															</FormGroup>

														</>
													)}


													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Pattern:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="pattern"
																control={control}
																defaultValue={data.additionalFields.pattern}
																render={({ field }) => (
																	<>
																		<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.pattern}>
																			<option value="">Select pattern</option>
																			<option value="Indian Print">Indian Print</option>
																			<option value="Korean Print">Korean Print</option>
																			<option value="Classic Print">Classic Print</option>
																			<option value="English Print">English Print</option>
																		</Input>
																		{errors.additionalFields?.pattern && (
																			<FormFeedback>{errors.additionalFields.pattern.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Pattern is required" }}
															/>
														</div>
													</FormGroup>

													{['Tamil', 'Telugu', 'Hindi', 'Kannada'].includes(language) && (
														<FormGroup className="form-group mb-3 row">
															<Label className="col-xl-3 col-sm-6 mb-0">
																Bible Size:
															</Label>
															<div className="col-xl-6 col-sm-7">
																<Controller
																	name="bibleSize"
																	control={control}
																	defaultValue={data.additionalFields.bibleSize}
																	render={({ field }) => (
																		<>
																			<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.bibleSize}>
																				<option value="">Select bible Size</option>
																				<option value="pocket">Pocket Size</option>
																				<option value="compact">Compact</option>
																				<option value="demmy">Demmy</option>
																				<option value="large">Large Print</option>
																				<option value="pulpit">Pulpit</option>
																				{/* Add more options here */}
																			</Input>
																			{errors.additionalFields?.bibleSize && (
																				<FormFeedback>{errors.additionalFields.bibleSize.message}</FormFeedback>
																			)}
																		</>
																	)}
																	rules={{ required: "bibleSize is required" }}
																/>
															</div>
														</FormGroup>)}

													{language === 'English' && (
														<FormGroup className="form-group mb-3 row">
															<Label className="col-xl-3 col-sm-6 mb-0">
																Bible Size:
															</Label>
															<div className="col-xl-6 col-sm-7">
																<Controller
																	name="bibleSize"
																	control={control}
																	defaultValue={data.additionalFields.bibleSize}
																	render={({ field }) => (
																		<>
																			<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.bibleSize}>
																				<option value="">Select bible Size</option>
																				<option value="Pocket Size">Pocket Size</option>
																				<option value="Compact Size">Compact Size</option>
																				<option value="Personal Size">Personal Size</option>
																				<option value="Thinline Pocket Size">Thinline Pocket Size</option>
																				<option value="Thinline Compact">Thinline Compact</option>
																				<option value="Thinline Semi Compact">Thinline Semi Compact</option>
																				<option value="Thinline Medium">Thinline Medium</option>
																				<option value="Thinline Large">Thinline Large</option>
																				<option value="Medium Size">Medium Size</option>
																				<option value="Large Print">Large Print</option>
																				<option value="Giant Print">Giant Print</option>
																				<option value="Pulpit">Pulpit</option>
																				{/* Add more options here */}
																			</Input>
																			{errors.additionalFields?.bibleSize && (
																				<FormFeedback>{errors.additionalFields.bibleSize.message}</FormFeedback>
																			)}
																		</>
																	)}
																	rules={{ required: "bibleSize is required" }}
																/>
															</div>
														</FormGroup>)}


													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Width:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="width"
																control={control}
																defaultValue={data.additionalFields.width}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.additionalFields?.width}
																		/>
																		{errors.fontSize && (
																			<FormFeedback>{errors.additionalFields.width.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Width is required" }}
															/>
														</div>
													</FormGroup>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Height:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="height"
																control={control}
																defaultValue={data.additionalFields.height}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.additionalFields?.height}
																		/>
																		{errors.fontSize && (
																			<FormFeedback>{errors.additionalFields.height.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Height is required" }}
															/>
														</div>
													</FormGroup>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Bible Color:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="color"
																control={control}
																defaultValue={data.additionalFields.color}
																render={({ field }) => (
																	<>
																		<Input
																			className="form-control"
																			{...field}
																			type="text"
																			invalid={!!errors.additionalFields?.color}
																		/>
																		{errors.fontSize && (
																			<FormFeedback>{errors.additionalFields.color.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Bible color is required" }}
															/>
														</div>
													</FormGroup>
												</>
											)}

											{category === 'Board' && (
												<>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-6 mb-0">
															Board Language:
														</Label>
														<div className="col-xl-6 col-sm-7">
															<Controller
																name="boardLanguage"
																control={control}
																defaultValue={data.additionalFields.boardLanguage}
																render={({ field }) => (
																	<>
																		<Input type="select" className="form-control" {...field} invalid={!!errors.additionalFields?.boardLanguage}>
																			<option value="">Select Board Language</option>
																			<option value="tamil">Tamil</option>
																			<option value="english">English</option>
																			<option value="malayalam">Malayalam</option>
																		</Input>
																		{errors.additionalFields?.boardLanguage && (
																			<FormFeedback>{errors.additionalFields.boardLanguage.message}</FormFeedback>
																		)}
																	</>
																)}
																rules={{ required: "Board Language is required" }}
															/>
														</div>
													</FormGroup>

												</>
											)}


											<div className="form">
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Summary:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="summary"
															control={control}
															defaultValue={data.summary}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.summary}
																	/>
																	{errors.summary && (
																		<FormFeedback>{errors.summary.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Summary is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Offer Price:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="offerPrice"
															control={control}
															defaultValue={data.offerPrice}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="number"
																		invalid={!!errors.offerPrice}
																	/>
																	{errors.offerPrice && (
																		<FormFeedback>{errors.offerPrice.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Offer Price is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Offer Percentage:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="offerPercentage"
															control={control}
															defaultValue={data.offerPercentage}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="number"
																		invalid={!!errors.offerPercentage}
																	/>
																	{errors.offerPercentage && (
																		<FormFeedback>{errors.offerPercentage.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Offer Percentage is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Regular Price:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="regularPrice"
															control={control}
															defaultValue={data.regularPrice}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="number"
																		invalid={!!errors.regularPrice}
																	/>
																	{errors.regularPrice && (
																		<FormFeedback>{errors.regularPrice.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Regular Price is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Stock :
													</Label>

													<div className="col-xl-8 col-sm-7">
														<Controller
															name="stock"
															control={control}
															defaultValue={data.stock}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="number"
																		invalid={!!errors.stock}
																	/>
																	{errors.stock && (
																		<FormFeedback>{errors.stock.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Stock is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Add Description :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<CustomMDEditor name="description" control={control} />
													</div>
												</FormGroup>
											</div>
											<div className="offset-xl-3 offset-sm-4">
												<Button type="submit" color="primary">
													Add
												</Button>
												<Button type="button" color="light">
													Discard
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Add_product;
