import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
    FormFeedback
} from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Add_Board_product = () => {

    const [data, setData] = useState({
        name: "",
        slug:"",
        sku: "",
        category: "",
        summary: "",
        description: "",
        images: [],
        boardType: '',
        boardLanguage: '',
        minPrice: '',
        maxPrice: '',
        offerPercentage: '',
        variations: [{
            size: '',
            frameType: '',
            boardPrice: ''
        }]

    });
    const [uniqueId, setUniqueId] = useState(Date.now());
    const [quantity, setQuantity] = useState(1);
    const [file, setFile] = useState([]);
    const [selectedVariationIndex, setSelectedVariationIndex] = useState(0);

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors },
        watch,
        reset
    } = useForm({
        defaultValues: data,
    });
    const fileChange = (e) => {
        setFile([...file, ...e.target.files]);
    };

    const clearFileState = () => {
        setFile([]);
    };

    const handleValidSubmit = async (data) => {
        console.log(data, "data");
        try {
            const formData = new FormData();
            const values = getValues();

            // for (const [key, value] of Object.entries(values)) {
            // 	formData.append(key, value);
            // }

            for (var j = 0; j < file.length; j++) {
                formData.append("images", file[j]);
            }

            // for (const [key, value] of Object.entries(data)) {
            //     if (key === "size") {
            //         if (Array.isArray(value)) {
            //             // If "size" is an array, store its values individually in FormData
            //             for (let i = 0; i < value.length; i++) {
            //                 formData.append("size", value[i]);
            //             }
            //         } else {
            //             // If "size" is not an array, store it as a single value in FormData
            //             formData.append("size", value);
            //         }
            //     } else {
            //         formData.append(key, value);
            //     }
            // }

            Object.keys(data).forEach((key) => {
                if (key === 'images') {
                    data.images.forEach((image, index) => {
                        formData.append(`images[${index}]`, image);
                    });
                } else if (key === 'variations') {
                    data.variations.forEach((variation, index) => {
                        Object.keys(variation).forEach((variationKey) => {
                            formData.append(`variations[${index}][${variationKey}]`, variation[variationKey]);
                        });
                    });
                } else {
                    formData.append(key, data[key]);
                }
            });
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "board/create",
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        //Authorization: `${token && token.token}`,
                    },
                }
            );

            if (response.data.success) {
                console.log(response.data, "board")
                toast.success("Product added successfully");
                reset();
                clearFileState();
            } else {
                toast.error("An error occurred while creating the product");
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const { data } = error.response;
                if (data && data.message) {
                    if (Array.isArray(data.message)) {
                        toast.error(data.message[0]);
                    } else {
                        toast.error(data.message);
                    }
                } else {
                    toast.error("An error occurred while processing the request");
                }
            } else if (error.request) {
                toast.error("No response received from the server");
            } else {
                toast.error("An error occurred while sending the request");
            }
        }
    };

    const CustomMDEditor = ({ name, control }) => {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={data.description}
                render={({ field }) => (
                    <MDEditor value={field.value} onChange={field.onChange} />
                )}
            />
        );
    };

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        setUniqueId(Date.now());
    }

    const category = watch('category');
    const language = watch('language');
    const boardType = watch('boardType');

    const [variations, setVariations] = useState([]);

    // const addVariation = () => {
    // 	console.log("add variations")
    // 	// Create a new variation with empty values and add it to the list of variations
    // 	const newVariation = {
    // 		size: "",
    // 		frameType: "",
    // 		boardPrice: ""
    // 	};
    // 	console.log(newVariation, " new variation");
    // 	setData((prevData) => ({
    // 		...prevData,
    // 		additionalFields: {
    // 			...prevData.additionalFields,
    // 			variations: [...prevData.additionalFields.variations, newVariation]
    // 		}
    // 	}));
    // };

    const addVariation = () => {
        console.log("Adding new variation");
        const newVariation = {
            size: "",
            frameType: "",
            boardPrice: "",
        };

        setData((prevData) => {
            const updatedVariations = [...prevData.variations, newVariation];

            return {
                ...prevData,
                variations: updatedVariations,
            };
        });
    };



    const removeVariation = (indexToRemove) => {
        setData((prevData) => {
            const updatedVariations = prevData.variations.filter((_, index) => index !== indexToRemove);

            return {
                ...prevData,
                variations: updatedVariations,
            };
        });
    };

    return (
        <Fragment>
            <Breadcrumb title="Add Board" parent="Physical" />
            <ToastContainer />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Add Board</h5>
                            </CardHeader>
                            <CardBody>
                                <Row className="product-adding">
                                    <Col xl="5">
                                        <div className="add-product">
                                            <Col xl="3">
                                                <div className="add-product">
                                                    {file && file.length < 6 && (
                                                        <Row>
                                                            <Col xl="12 xl-50" sm="6 col-3">
                                                                <Input
                                                                    key={uniqueId}
                                                                    className="upload"
                                                                    type="file"
                                                                    multiple
                                                                    name="images"
                                                                    accept="image/png, image/gif,image/webp, image/jpeg"
                                                                    onChange={fileChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row style={{ marginTop: "15px" }}>
                                                        {file &&
                                                            file.length > 0 &&
                                                            file.map((item, index) => {
                                                                return (
                                                                    <Col xl="6" sm="3 col-3">
                                                                        <div key={index}>
                                                                            <img
                                                                                style={{ width: "100%" }}
                                                                                src={URL.createObjectURL(item)}
                                                                                alt=""
                                                                            />
                                                                            <Button
                                                                                style={{ marginTop: "10px" }}
                                                                                type="button"
                                                                                color="secondary"
                                                                                size="sm"
                                                                                onClick={() => deleteFile(index)}
                                                                            >
                                                                                delete
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col xl="7">
                                        <Form
                                            className="needs-validation add-product-form"
                                            onSubmit={handleSubmit(handleValidSubmit)}
                                        >

                                            <FormGroup className="form-group mb-3 row">
                                                <Label className="col-xl-3 col-sm-4 mb-0">
                                                    Category:
                                                </Label>
                                                <div className="col-xl-8 col-sm-7">
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        defaultValue={data.category}
                                                        render={({ field }) => (
                                                            <>
                                                                <Input type="select" className="form-control" {...field} invalid={!!errors.category}>
                                                                    <option value="">Select Category</option>

                                                                    <option value="Board">Board</option>

                                                                    {/* Add more options here */}
                                                                </Input>
                                                                {errors.category && (
                                                                    <FormFeedback>{errors.category.message}</FormFeedback>
                                                                )}
                                                            </>
                                                        )}
                                                        rules={{ required: "Category is required" }}
                                                    />
                                                </div>
                                            </FormGroup>

                                            <div className="form form-label-center">
                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        Product Name:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="name"
                                                            control={control}
                                                            defaultValue={data.name}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.name}
                                                                    />
                                                                    {errors.name && (
                                                                        <FormFeedback>{errors.name.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Product Name is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Product URL:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="slug"
															control={control}
															defaultValue={data.slug}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.slug}
																	/>
																	{errors.slug && (
																		<FormFeedback>{errors.slug.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Product URL is required" }}
														/>
													</div>
												</FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        SKU:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="sku"
                                                            control={control}
                                                            defaultValue={data.sku}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.sku}
                                                                    />
                                                                    {errors.sku && (
                                                                        <FormFeedback>{errors.sku.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "SKU is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>


                                            </div>

                                            {category === 'Board' && (
                                                <>

                                                    <FormGroup className="form-group mb-3 row">
                                                        <Label className="col-xl-3 col-sm-6 mb-0">
                                                            Board Language:
                                                        </Label>
                                                        <div className="col-xl-6 col-sm-7">
                                                            <Controller
                                                                name="boardLanguage"
                                                                control={control}
                                                                defaultValue={data.boardLanguage}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.boardLanguage}>
                                                                            <option value="">Select Board Language</option>
                                                                            <option value="Tamil">Tamil</option>
                                                                            <option value="English">English</option>
                                                                            <option value="Malayalam">Malayalam</option>
                                                                            <option value="Bilingual">Bilingual</option>
                                                                        </Input>
                                                                        {errors.boardLanguage && (
                                                                            <FormFeedback>{errors.boardLanguage.message}</FormFeedback>
                                                                        )}
                                                                    </>
                                                                )}
                                                                rules={{ required: "Board Language is required" }}
                                                            />
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup className="form-group mb-3 row">
                                                        <Label className="col-xl-3 col-sm-6 mb-0">
                                                            Board Type:
                                                        </Label>
                                                        <div className="col-xl-6 col-sm-7">
                                                            <Controller
                                                                name="boardType"
                                                                control={control}
                                                                defaultValue={data.boardType}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.boardType}>
                                                                            <option value="">Select Board Type</option>
                                                                            <option value="Small Size">Small Size Board</option>
                                                                            <option value="Square">Square Board</option>
                                                                            <option value="Horizontal">Horizontal Board</option>
                                                                            <option value="Vertical">Vertical Board</option>
                                                                            <option value="Long Size">Long Size Board</option>
                                                                        </Input>
                                                                        {errors.boardType && (
                                                                            <FormFeedback>{errors.boardType.message}</FormFeedback>
                                                                        )}
                                                                    </>
                                                                )}
                                                                rules={{ required: "Board Type is required" }}
                                                            />
                                                        </div>
                                                    </FormGroup>

                                                    {boardType === 'Small Size' &&

                                                        <div>
                                                            {data.variations && data.variations.map((variation, index) => (

                                                                <div key={index} className="board-container">

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Size:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].size`}
                                                                                control={control}
                                                                                defaultValue={variation.size}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[index]?.size} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Small Board Size</option>
                                                                                            <option value="6x4">6x4</option>
                                                                                            <option value="12x8">12x8</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[index]?.size && (
                                                                                            <FormFeedback>{errors.variations[index].size.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Size is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Type:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].frameType`} // Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].frameType} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.frameType} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Frame Type</option>
                                                                                            <option value="Frame">Frame</option>
                                                                                            <option value="Frameless">Frameless</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[selectedVariationIndex]?.frameType && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].frameType.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Type is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Board Price:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].boardPrice`}// Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].boardPrice} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="text" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.boardPrice} onChange={(e) => { field.onChange(e) }} />
                                                                                        {errors.variations?.[selectedVariationIndex]?.boardPrice && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].boardPrice.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Board Price is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    {index < 1 && (
                                                                        <Button type="button" onClick={addVariation}>
                                                                            Add
                                                                        </Button>
                                                                    )}

                                                                    {index > 0 && (
                                                                        <Button type="button" onClick={() => removeVariation(index)}>
                                                                            Remove
                                                                        </Button>
                                                                    )}
                                                                </div>

                                                            ))}
                                                        </div>
                                                    }

                                                    {boardType === 'Square' &&

                                                        <div>
                                                            {data.variations && data.variations.map((variation, index) => (

                                                                <div key={index} className="board-container">

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Size:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].size`}
                                                                                control={control}
                                                                                defaultValue={variation.size}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[index]?.size} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Small Board Size</option>
                                                                                            <option value="12x12">12x12</option>n
                                                                                            <option value="14x14">14x14</option>
                                                                                            <option value="20x20">20x20</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[index]?.size && (
                                                                                            <FormFeedback>{errors.variations[index].size.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Size is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Type:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].frameType`} // Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].frameType} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.frameType} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Frame Type</option>
                                                                                            <option value="Frame">Frame</option>
                                                                                            <option value="Frameless">Frameless</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[selectedVariationIndex]?.frameType && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].frameType.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Type is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Board Price:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].boardPrice`}// Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].boardPrice} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="text" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.boardPrice} onChange={(e) => { field.onChange(e) }} />
                                                                                        {errors.variations?.[selectedVariationIndex]?.boardPrice && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].boardPrice.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Board Price is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    {index < 1 && (
                                                                        <Button type="button" onClick={addVariation}>
                                                                            Add
                                                                        </Button>
                                                                    )}

                                                                    {index > 0 && (
                                                                        <Button type="button" onClick={() => removeVariation(index)}>
                                                                            Remove
                                                                        </Button>
                                                                    )}
                                                                </div>

                                                            ))}
                                                        </div>
                                                    }

                                                    {boardType === 'Horizontal' &&

                                                        <div>
                                                            {data.variations && data.variations.map((variation, index) => (

                                                                <div key={index} className="board-container">

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Size:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].size`}
                                                                                control={control}
                                                                                defaultValue={variation.size}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[index]?.size} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Small Board Size</option>
                                                                                            <option value="12x8">12x8</option>
                                                                                            <option value="18x12">18x12</option>
                                                                                            <option value="20x14">20x14</option>
                                                                                            <option value="24x20">24x20</option>
                                                                                            <option value="20x18">20x18</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[index]?.size && (
                                                                                            <FormFeedback>{errors.variations[index].size.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Size is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Type:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].frameType`} // Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].frameType} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.frameType} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Frame Type</option>
                                                                                            <option value="Frame">Frame</option>
                                                                                            <option value="Frameless">Frameless</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[selectedVariationIndex]?.frameType && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].frameType.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Type is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Board Price:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].boardPrice`}// Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].boardPrice} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="text" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.boardPrice} onChange={(e) => { field.onChange(e) }} />
                                                                                        {errors.variations?.[selectedVariationIndex]?.boardPrice && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].boardPrice.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Board Price is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    {index < 1 && (
                                                                        <Button type="button" onClick={addVariation}>
                                                                            Add
                                                                        </Button>
                                                                    )}

                                                                    {index > 0 && (
                                                                        <Button type="button" onClick={() => removeVariation(index)}>
                                                                            Remove
                                                                        </Button>
                                                                    )}
                                                                </div>

                                                            ))}
                                                        </div>
                                                    }

                                                    {boardType === 'Vertical' &&

                                                        <div>
                                                            {data.variations && data.variations.map((variation, index) => (

                                                                <div key={index} className="board-container">

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Size:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].size`}
                                                                                control={control}
                                                                                defaultValue={variation.size}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[index]?.size} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Small Board Size</option>
                                                                                            <option value="8x12">8x12</option>
                                                                                            <option value="12x18">12x18</option>
                                                                                            <option value="14x20">14x20</option>
                                                                                            <option value="20x24">20x24</option>
                                                                                            <option value="18x20">18x20</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[index]?.size && (
                                                                                            <FormFeedback>{errors.variations[index].size.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Size is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Type:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].frameType`} // Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].frameType} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.frameType} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Frame Type</option>
                                                                                            <option value="Frame">Frame</option>
                                                                                            <option value="Frameless">Frameless</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[selectedVariationIndex]?.frameType && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].frameType.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Type is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Board Price:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].boardPrice`}// Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].boardPrice} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="text" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.boardPrice} onChange={(e) => { field.onChange(e) }} />
                                                                                        {errors.variations?.[selectedVariationIndex]?.boardPrice && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].boardPrice.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Board Price is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    {index < 1 && (
                                                                        <Button type="button" onClick={addVariation}>
                                                                            Add
                                                                        </Button>
                                                                    )}

                                                                    {index > 0 && (
                                                                        <Button type="button" onClick={() => removeVariation(index)}>
                                                                            Remove
                                                                        </Button>
                                                                    )}
                                                                </div>

                                                            ))}
                                                        </div>
                                                    }

                                                    {boardType === 'Long Size' &&

                                                        <div>
                                                            {data.variations && data.variations.map((variation, index) => (

                                                                <div key={index} className="board-container">

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Size:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].size`}
                                                                                control={control}
                                                                                defaultValue={variation.size}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[index]?.size} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Small Board Size</option>
                                                                                            <option value="24x12">24x12</option>
                                                                                            <option value="30x12">30x12</option>
                                                                                            <option value="40x12">40x12</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[index]?.size && (
                                                                                            <FormFeedback>{errors.variations[index].size.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Size is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Frame Type:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].frameType`} // Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].frameType} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.frameType} onChange={(e) => { field.onChange(e) }}>
                                                                                            <option value="">Select Frame Type</option>
                                                                                            <option value="Frame">Frame</option>
                                                                                            <option value="Frameless">Frameless</option>
                                                                                        </Input>
                                                                                        {errors.variations?.[selectedVariationIndex]?.frameType && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].frameType.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Frame Type is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>

                                                                    <FormGroup className="form-group mb-3 row">
                                                                        <Label className="col-xl-4 col-sm-6 mb-0">
                                                                            Board Price:
                                                                        </Label>
                                                                        <div className="col-xl-8 col-sm-7">
                                                                            <Controller
                                                                                name={`variations[${index}].boardPrice`}// Update the name based on the selected variation
                                                                                control={control}
                                                                                defaultValue={data.variations[selectedVariationIndex].boardPrice} // Set the default value based on the selected variation
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <Input type="text" className="form-control" {...field} invalid={!!errors.variations?.[selectedVariationIndex]?.boardPrice} onChange={(e) => { field.onChange(e) }} />
                                                                                        {errors.variations?.[selectedVariationIndex]?.boardPrice && (
                                                                                            <FormFeedback>{errors.variations[selectedVariationIndex].boardPrice.message}</FormFeedback>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                rules={{ required: "Board Price is required" }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    {index < 1 && (
                                                                        <Button type="button" onClick={addVariation}>
                                                                            Add
                                                                        </Button>
                                                                    )}

                                                                    {index > 0 && (
                                                                        <Button type="button" onClick={() => removeVariation(index)}>
                                                                            Remove
                                                                        </Button>
                                                                    )}
                                                                </div>

                                                            ))}
                                                        </div>
                                                    }

                                                </>
                                            )}

                                            <FormGroup className="form-group mb-3 row">
                                                <Label className="col-xl-3 col-sm-4 mb-0">
                                                    Minimum Price:
                                                </Label>
                                                <div className="col-xl-8 col-sm-7">
                                                    <Controller
                                                        name="minPrice"
                                                        control={control}
                                                        defaultValue={data.minPrice}
                                                        render={({ field }) => (
                                                            <>
                                                                <Input
                                                                    className="form-control"
                                                                    {...field}
                                                                    type="number"
                                                                    invalid={!!errors.minPrice}
                                                                />
                                                                {errors.minPrice && (
                                                                    <FormFeedback>{errors.minPrice.message}</FormFeedback>
                                                                )}
                                                            </>
                                                        )}
                                                        rules={{ required: "Minimum Price is required" }}
                                                    />
                                                </div>
                                            </FormGroup>

                                            <FormGroup className="form-group mb-3 row">
                                                <Label className="col-xl-3 col-sm-4 mb-0">
                                                    Maxmium Price:
                                                </Label>
                                                <div className="col-xl-8 col-sm-7">
                                                    <Controller
                                                        name="maxPrice"
                                                        control={control}
                                                        defaultValue={data.maxPrice}
                                                        render={({ field }) => (
                                                            <>
                                                                <Input
                                                                    className="form-control"
                                                                    {...field}
                                                                    type="number"
                                                                    invalid={!!errors.maxPrice}
                                                                />
                                                                {errors.minPrice && (
                                                                    <FormFeedback>{errors.maxPrice.message}</FormFeedback>
                                                                )}
                                                            </>
                                                        )}
                                                        rules={{ required: "Maximum Price is required" }}
                                                    />
                                                </div>
                                            </FormGroup>

                                            <FormGroup className="form-group mb-3 row">
                                                <Label className="col-xl-3 col-sm-4 mb-0">
                                                    offer Percentage:
                                                </Label>
                                                <div className="col-xl-8 col-sm-7">
                                                    <Controller
                                                        name="offerPercentage"
                                                        control={control}
                                                        defaultValue={data.offerPercentage}
                                                        render={({ field }) => (
                                                            <>
                                                                <Input
                                                                    className="form-control"
                                                                    {...field}
                                                                    type="number"
                                                                    invalid={!!errors.offerPercentage}
                                                                />
                                                                {errors.minPrice && (
                                                                    <FormFeedback>{errors.offerPercentage.message}</FormFeedback>
                                                                )}
                                                            </>
                                                        )}
                                                        rules={{ required: "Offer Percentage is required" }}
                                                    />
                                                </div>
                                            </FormGroup>


                                            <div className="form">
                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        Summary:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="summary"
                                                            control={control}
                                                            defaultValue={data.summary}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.summary}
                                                                    />
                                                                    {errors.summary && (
                                                                        <FormFeedback>{errors.summary.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Summary is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>



                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4">
                                                        Add Description :
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7 description-sm">
                                                        <CustomMDEditor name="description" control={control} />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="offset-xl-3 offset-sm-4">
                                                <Button type="submit" color="primary">
                                                    Add
                                                </Button>
                                                <Button type="button" color="light">
                                                    Discard
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Add_Board_product;
